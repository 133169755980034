<template>
  <div class="cBox">
  <div class="container content-box">
    <div class="fundheader">
      <h2>E.T Technologies</h2>
    </div>
    <div class="email">
      <MailFilled></MailFilled>
      <h1>oskk9527@gmail.com</h1>
    </div>
  </div>
</div>
</template>
<script>
import { MailFilled } from '@ant-design/icons-vue';
export default {
  components: {
    MailFilled
  },
}
</script>
